header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 1rem 0 1rem;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    padding: 2rem 1rem;
    background-color: var(--color-primary);
  }

  header h1 {
    width: 60px;
    height: 60px;
    margin: 0 60px;
    order: 1;
    background: white;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
  }

  header button {
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    appearance: none;
    padding: 0; /* Fixes an iOS Safari issue */
    border: 1px solid white;
    color: white;
    border-radius: 50%;
    background: none;
  }

  header button.is-active {
    background: white;
    color: var(--color-primary);
  }

  header button:first-of-type {
    order: 0;
  }

  header button:last-of-type {
    order: 2;
  }