.input-wrapper {
    position: relative;
    margin-bottom: 60px;
    padding: 0 20px 60px;
    background-color: var(--color-primary);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  .boring-👏less-input {
    display: block; /* So that we can center it */
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
    padding: 20px;
    border: none;
    border-radius: var(--border-radius);
    font-family: inherit; /* For Firefox */
    font-size: 18px;
    line-height: 1.5;
  }

  .boring-👏less-input:focus,
  .boring-👏less-input:active {
    outline: none;
    box-shadow: inset 0 0 2px var(--color-primary);
  }

  .color-options {
    --background-color: var(--color-primary);
    --border-color: white;

    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    transform: translateY(50%);
    max-width: 320px;
    margin: 0 auto;
    padding: 10px;
    border-radius: var(--border-radius);
    border: 2px solid var(--border-color);
    background-color: var(--background-color);
    text-align: center;
  }

  @media (prefers-color-scheme: dark) {
    .color-options {
      --border-color: var(--background-color-body);
    }
  }

  .color-options input {
    display: none;
  }

  .color-options label {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid white;
    background-color: white;
    cursor: pointer;
    transition: transform 0.05s ease-in;
  }

  .color-options input:not(:checked) + label:hover {
    transform: scale(1.1);
  }

  .color-options label ~ label {
    margin-left: 5px;
  }

  .color-options :checked + label {
    transform: scale(1.3);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  }