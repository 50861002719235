.output-wrapper {
    max-width: 540px;
    margin: 0 auto;
    padding: 0 20px 20px;
    text-align: center;
  }

  .amazing-👏ful-output {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
  }

  .amazing-👏ful-output:empty + button {
    display: none;
  }

  .button-primary {
    margin: 1rem 0 2rem;
    padding: 10px 15px;
    border: 2px solid var(--color-button);
    border-radius: 3px;
    background: none;
    color: var(--color-button);
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 14px;
    font-weight: 600;
  }

  .button-primary.is-copied {
    background: var(--color-button);
    color: white;
  }
