@charset "UTF-8";
:root {
  --color-font: #4B4B4B;
  --color-primary: #039be5;
  --color-button: #039be5;
  --color-link: #0000EE;
  --background-color-body: #fff;
  --background-color-main: #eee;
  --border-radius: 10px;
  --transition-timing: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color-body: #222;
    --background-color-main: #2a2a2a;
    --color-font: rgba(255, 255, 255, 0.9);
    --color-primary: #154359;
    --color-link: #ff47e2;
  }
}
html {
  box-sizing: border-box;
  font-size: 18px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.4;
  color: var(--color-font);
  background-color: var(--background-color-body);
  margin: 0;
}

a {
  color: var(--color-link);
}

button {
  outline: none;
  cursor: pointer;
}

.main {
  margin: 0 1rem;
  background-color: var(--background-color-main);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.footer {
  margin: 1rem;
  padding: 1rem;
  background-color: var(--background-color-main);
  border-radius: var(--border-radius);
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 1rem 0 1rem;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 2rem 1rem;
  background-color: var(--color-primary);
}

header h1 {
  width: 60px;
  height: 60px;
  margin: 0 60px;
  order: 1;
  background: white;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
}

header button {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  /* Fixes an iOS Safari issue */
  border: 1px solid white;
  color: white;
  border-radius: 50%;
  background: none;
}

header button.is-active {
  background: white;
  color: var(--color-primary);
}

header button:first-of-type {
  order: 0;
}

header button:last-of-type {
  order: 2;
}

.input-wrapper {
  position: relative;
  margin-bottom: 60px;
  padding: 0 20px 60px;
  background-color: var(--color-primary);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.boring-👏less-input {
  display: block;
  /* So that we can center it */
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
  padding: 20px;
  border: none;
  border-radius: var(--border-radius);
  font-family: inherit;
  /* For Firefox */
  font-size: 18px;
  line-height: 1.5;
}

.boring-👏less-input:focus,
.boring-👏less-input:active {
  outline: none;
  box-shadow: inset 0 0 2px var(--color-primary);
}

.color-options {
  --background-color: var(--color-primary);
  --border-color: white;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  transform: translateY(50%);
  max-width: 320px;
  margin: 0 auto;
  padding: 10px;
  border-radius: var(--border-radius);
  border: 2px solid var(--border-color);
  background-color: var(--background-color);
  text-align: center;
}

@media (prefers-color-scheme: dark) {
  .color-options {
    --border-color: var(--background-color-body);
  }
}
.color-options input {
  display: none;
}

.color-options label {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid white;
  background-color: white;
  cursor: pointer;
  transition: transform 0.05s ease-in;
}

.color-options input:not(:checked) + label:hover {
  transform: scale(1.1);
}

.color-options label ~ label {
  margin-left: 5px;
}

.color-options :checked + label {
  transform: scale(1.3);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.output-wrapper {
  max-width: 540px;
  margin: 0 auto;
  padding: 0 20px 20px;
  text-align: center;
}

.amazing-👏ful-output {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.amazing-👏ful-output:empty + button {
  display: none;
}

.button-primary {
  margin: 1rem 0 2rem;
  padding: 10px 15px;
  border: 2px solid var(--color-button);
  border-radius: 3px;
  background: none;
  color: var(--color-button);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 14px;
  font-weight: 600;
}

.button-primary.is-copied {
  background: var(--color-button);
  color: white;
}