:root {
    --color-font: #4B4B4B;
    --color-primary: #039be5;
    --color-button: #039be5;
    --color-link: #0000EE;
    --background-color-body: #fff;
    --background-color-main: #eee;
    --border-radius: 10px;
    --transition-timing: cubic-bezier(0.68, -0.55, 0.265, 1.55);

  }

  @media (prefers-color-scheme: dark) {
    :root {
      --background-color-body: #222;
      --background-color-main: #2a2a2a;
      --color-font: rgba(255, 255, 255, 0.9);
      --color-primary: #154359;
      --color-link: #ff47e2;
    }
  }

  html {
    box-sizing: border-box;
    font-size: 18px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.4;
    color: var(--color-font);
    background-color: var(--background-color-body);
    margin: 0;
  }

  a {
    color: var(--color-link);
  }

  button {
    outline: none;
    cursor: pointer;
  }

  .main {
    margin: 0 1rem;
    background-color: var(--background-color-main);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  .footer {
    margin: 1rem;
    padding: 1rem;
    background-color: var(--background-color-main);
    border-radius: var(--border-radius);
  }